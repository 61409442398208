export default class CalendarNotifications {
	constructor (doctorId, addressId, addressContainer) {
		this.doctorId = doctorId;
		this.addressId = addressId;
		this.addressContainer = addressContainer;

		this.emailsContainer = this.addressContainer.find('[data-id=calendar-notifications-emails]');
		this.inputTemplate = this.parseAndRemoveInputTemplate();

		this.fetchCalendarNotifications();
		this.registerButtonsBindings();
		this.markContainerAsInitialized();
	}

	fetchCalendarNotifications () {
		$.ajax({
			contentType: 'application/json',
			type: 'GET',
			url: `/api/v1/external/doctors/${this.doctorId}/addresses/${this.addressId}/notifications`,
			dataType: 'json',
		}).done(data => {
			let items = data._items;
			if (items.length > 0) {
				items.forEach(item => {
					this.appendInput(item.email);
				})
			}
		});
	}

	saveCalendarNotifications () {
		const inputs = this.emailsContainer.find('input')
			.filter((index, item) => $(item).val() !== '');

		let payload = {emails: []};
		inputs.each((index, item) => {
			payload.emails.push($(item).val());
		});

		$.ajax({
			contentType: 'application/json',
			type: 'POST',
			url: `/api/v1/external/doctors/${this.doctorId}/addresses/${this.addressId}/notifications`,
			dataType: 'json',
			data: JSON.stringify(payload),
		}).done(() => {
			window.alert('Calendar notifications saved');
		});
	}

	parseAndRemoveInputTemplate () {
		let templateElement = this.emailsContainer.find('div');
		const template = templateElement[0].outerHTML;
		templateElement.remove();

		return template;
	}

	registerButtonsBindings () {
		this.addressContainer.find(`[data-id=add-notification-email]`).on('click', () => {
			this.appendInput('');
		});

		this.addressContainer.find(`[data-id=save-notification-emails]`).on('click', () => {
			this.saveCalendarNotifications();
		});
	}

	appendInput (value) {
		let itemElement = $(this.inputTemplate);
		itemElement.find('input').val(value);
		this.emailsContainer.append(itemElement);
		itemElement.find('[data-id=remove-notification-email]').on('click', () => itemElement.remove());
	}

	markContainerAsInitialized () {
		this.emailsContainer.data('initialized', true);
	}
}
