import '../../../../../node_modules/select2/dist/js/select2.min';

const swal = require('sweetalert2');

export default class AddressServiceManager {
	constructor (integrationId) {
		this.integrationId = integrationId;

		this.setup();
	}

	setup () {
		const self = this;

		$(document.body)
			.on('show.bs.collapse', '[data-id="collapsable-doctor-panel"]', function () {
				self.addressServiceManage($(this));
			});
	}

	addressServiceManage ($panel) {
		const self = this;

		let $managerButton = $panel.find('[data-id=btn-matched-address-service]');
		if (!$managerButton.length) {
			return;
		}
		let $manageContainer = $panel.find('[data-id=manage-matched-services-form-container]');
		let $updateButton = $panel.find('[data-id=btn-update-calendar-address-services]');

		$managerButton.click(function () {
			AddressServiceManager.InitialiseDropDowns($manageContainer);
			$manageContainer.removeClass('hidden');
		});

		let $mappingId = $panel.data('addressMappingId');

		$updateButton.click(function () {
			$(this).prop('disabled', true);

			let $selectedAddressServiceIds = [];
			let $selectedAddressServices = $manageContainer.find('select option:selected');

			$.each($selectedAddressServices, function () {
				if($(this).val().length) {
					$selectedAddressServiceIds.push($(this).val());
				}
			});

			if (!$selectedAddressServiceIds.length)
			{
				swal(
					'Oops...',
					'Please select minimum 1 service!',
					'warning'
				);

				return;
			}

			let $data = {services: $selectedAddressServiceIds};

			$.ajax({
				type: 'PUT',
				url: `/api/v1/integrations/${self.integrationId}/matches/${$mappingId}/address-service`,
				dataType: 'json',
				contentType: 'application/json',
				'data': JSON.stringify($data)
			}).done(function () {
				swal(
					'Success...',
					'Calendar address services updated.',
					'success'
				);
			}).fail(function () {
				swal(
					'Oops...',
					'Error occurred while trying update calendar address services.',
					'warning'
				);
			});
			$(this).prop('disabled', false);
		});
	}

	static InitialiseDropDowns ($container) {
		$container.find('select').select2({'width': '100%'});
	}
}