import '../../../../../node_modules/sweetalert2/dist/sweetalert2.all.min';
const swal = require('sweetalert2');
const concat = require('lodash/concat');
const findIndex = require('lodash/findIndex');

export default class IntegrationsMatcher {
	constructor (integrationId) {
		this.integrationId = integrationId;

		this.GetDoctors();
		this.SelectEventsListener();
		this.MatchDoctor();
		this.UnMatchDoctor();
		this.DuplicateAddress();
		this.SelectAllServices();
		this.unlockMapping();
	}

	static GetSelection($select)
	{
		let selections = IntegrationsMatcher.GetSelections($select);

		return selections[0];
	}

	static GetSelections($select)
	{
		let selections = $select.select2('data');

		return selections.filter((item) => item.id && item.id !== '0');
	}


	_GetDoctorsAjaxCall($doctorSelectBox, $addressSelectBox, $servicesSelectBox, $notificationsSelect) {
		let self = this;
		$.ajax({
			contentType: 'application/json',
			type: 'GET',
			url: `/api/v1/integrations/${this.integrationId}/external/doctors`,
			dataType: 'json'
		}).done(function (data) {
			self._SelectInit($doctorSelectBox, data, ['id', 'name', 'surname']);

			const doctor = IntegrationsMatcher.GetSelection($doctorSelectBox);

			if(doctor)
			{
				self._GetAddressesAjaxCall(doctor, $addressSelectBox, $servicesSelectBox, $notificationsSelect);
			}
		});
	}

	_GetAddressesAjaxCall(doctor, $addressSelectBox, $servicesSelectBox, $notificationsSelect) {
		let self = this;
		$.ajax({
			contentType: 'application/json',
			type: 'GET',
			url: `/api/v1/integrations/${this.integrationId}/external/doctors/${doctor.id}/addresses`,
			dataType: 'json'
		}).done(function (data) {
			self._SelectInit($addressSelectBox, data, ['id', 'name', 'city', 'street']);

			$addressSelectBox.attr('disabled', false);

			let address = IntegrationsMatcher.GetSelection($addressSelectBox);

			if(address)
			{
				self._GetNotifications(doctor, address, $notificationsSelect);
				self._GetServicesAjaxCall(doctor, address, $servicesSelectBox);
			}
		});

	}

	_GetNotifications(doctor, address, $notificationsSelect)
	{
		let self = this;
		$.ajax({
			contentType: 'application/json',
			type: 'GET',
			url: `/api/v1/external/doctors/${doctor.id}/addresses/${address.id}/notifications`,
			dataType: 'json',
		}).done(function (data) {

			let notifications = data['_items'].map(item => {
				return {
					id: item.id,
					name: item.email,
					selected: true
				}
			});

			const defaults = $notificationsSelect.data('defaultNotifications')
				.filter(item => {
					return -1 === findIndex(notifications, ['name', item])
				}).map(item => {
					return {
						id: item,
						name: item,
						selected: true
					}
				});

			notifications = concat(notifications, defaults);

			self._SelectInit($notificationsSelect, notifications, ['name']);

			$notificationsSelect.attr('disabled', false);
		});
	}

	_GetServicesAjaxCall(doctor, address, $servicesSelectBox) {
		let self = this;
		$.ajax({
			contentType: 'application/json',
			type: 'GET',
			url: `/api/v1/external/doctors/${doctor.id}/addresses/${address.id}/services`,
			dataType: 'json',
		}).done(function (data) {
			self._SelectInit($servicesSelectBox, data, ['id', 'name']);

			$servicesSelectBox.attr('disabled', false);
			$servicesSelectBox.closest('[data-id="doctor-panel"]')
				.find('[data-id="select-all-services"]').attr('disabled', false);
		});
	}

	_SelectInit($element, data, fields) {

		let options = {
			width: '100%',
			cache: false,
			templateResult: formatOption,
			templateSelection: formatOption,
			disabled: data.length === 0
		};

		const mappedData = data.map((item) => {
			const text = getText(item, fields);

			item['text'] = text;
			item['representation'] = text;

			return item;
		});

		if($element.prop('multiple'))
		{
			options.placeholder = $element.attr('placeholder');
		}
		else
		{
			data.unshift({
				id: '0',
				text: $element.attr('placeholder')
			});
		}

		options.data = mappedData;


		if($element.hasClass("select2-hidden-accessible"))
		{
			$element.select2('destroy').empty();
		}

		return $element.select2(options);

		function formatOption (data)
		{
			if(!data.id || data.id === '0')
			{
				return data.text;
			}

			if(data.representation)
			{
				return data.representation;
			}

			let item = [data.id, data.name];

			for(let field of ['surname', 'city'])
			{
				if(data[field])
				{
					item.push(data[field]);
				}
			}

			return item.join(' ');
		}

		function getText(data, fields)
		{
			let text = [];

			for (let key in data)
			{
				if(data.hasOwnProperty(key) && fields.indexOf(key) >= 0)
				{
					text.push(data[key]);
				}
			}

			return text.join(' ').trim();
		}
	}

	_CollectData($form) {
		let $btnSave = $form.find('[data-id="save"]');
		let integrationAddressId = $form.data('integration-address-id');

		let $doctor = $form.find('[data-id="choose-doctor"]');
		let $address = $form.find('[data-id="choose-doctor-address"]');
		let $services = $form.find('[data-id="choose-services"]');
		let $notifications = $form.find('[data-id="choose-notifications"]');

		let doctor = IntegrationsMatcher.GetSelection($doctor);
		let address = IntegrationsMatcher.GetSelection($address);
		let services = $services.select2('data');
		let notifications = $notifications.select2('data').map(notification => {
			return {
				email: notification['text']
			};
		});

		if (!doctor || !address || services.length === 0) {
			$btnSave.attr('disabled', true);
			return;
		}

		$btnSave.attr('disabled', false);

		return {
			webhook_id: this.integrationId,
			integration_address_id: integrationAddressId,
			external_address_id: address.id,
			services: services.map((item) => item.id),
			notifications: { _items: notifications },
			settings: {
				last24h: $form.find('[data-id="short-notice-booking"]').prop('checked'),
				weeks_ahead: $form.find('[data-id="weeks-ahead"]').val()
			}
		};
	}

	_SendData(mapping, callback) {
		const self = this;
		let integrationAddressId = mapping['integration_address_id'];

		$.ajax({
			contentType: 'application/json',
			url: `/api/v1/integrations/${this.integrationId}/matches`,
			type: 'POST',
			data: JSON.stringify(mapping),
			dataType: 'json',
		})
			.done(function () {
				self.RefreshMatching(integrationAddressId)
			})
			.fail(function (xhr) {
				callback();
				if( 409 === xhr.status)
				{
					swal(
						'Oops...',
						'Address already matched!',
						'warning'
					);
				}
				else
				{
					swal(
						'Oops...',
						'An error occured while matching doctor:<br>' + xhr.responseJSON.error,
						'error'
					)
				}
			});

	}

	RefreshMatching(integrationAddressId)
	{
		$.ajax({
			contentType: 'text/html',
			url: `/integrations/${this.integrationId}/matches/${integrationAddressId}/status`,
			type: 'GET',
			dataType: 'html'
		}).done(function (data) {
			$(`[data-integration-address-id=${integrationAddressId}]`).html(data);
		}).fail(function (){
			swal(
				'Oops...',
				'Could not refresh view, please reload page!',
				'warning'
			);
		});
	}

	SelectEventsListener () {
		let self = this,
			$body = $('body');

		$body.on('change', '[data-id="choose-doctor"]', function () {
			let $this = $(this);

			let $matchPanel = $this.parents('[data-id="doctor-panel"]');
			let $addressSelectBox = $matchPanel.find('[ data-id="choose-doctor-address"]');
			let $servicesSelectBox = $matchPanel.find('[data-id="choose-services"]');

			let doctor = IntegrationsMatcher.GetSelection($this);

			self._SelectInit($addressSelectBox, []);
			self._SelectInit($servicesSelectBox, []);

			if(doctor)
			{
				self._GetAddressesAjaxCall(doctor, $addressSelectBox, $servicesSelectBox);
			}
		});


		$body.on('change', '[data-id="choose-doctor-address"]', function () {
			let $this = $(this);
			let $matchPanel = $this.parents('[data-id="doctor-panel"]');
			let $servicesSelectBox = $matchPanel.find('[data-id="choose-services"]');
			let $doctorSelectBox = $matchPanel.find('[data-id="choose-doctor"]');
			let $notificationsSelect = $matchPanel.find('[data-id="choose-notifications"]');

			let doctor = IntegrationsMatcher.GetSelection($doctorSelectBox);
			let address = IntegrationsMatcher.GetSelection($this);


			self._SelectInit($servicesSelectBox, []);
			self._SelectInit($notificationsSelect, []);

			if(address)
			{
				self._GetServicesAjaxCall(doctor, address, $servicesSelectBox);
				self._GetNotifications(doctor, address, $notificationsSelect);
			}
		});

		$body.on('change', '[data-id="choose-services"]', function () {
			let $this = $(this);

			let selections = IntegrationsMatcher.GetSelections($this);

			$this.parents('[data-id="doctor-panel"]')
				.find('[data-id="save"]').prop('disabled', selections.length === 0);
		});
	}

	MatchDoctor () {
		let self = this;

		$('body').on('click', '[data-id="save"]', function () {
			let $this = $(this);
			let $panel = $this.parents('[data-id="parent"]');

			let doctorData = self._CollectData($panel);

			$this.prop('disabled', true);
			self._SendData(doctorData, function () {
				$this.prop('disabled', false);
			});
		});
	}

	UnMatchDoctor()
	{
		let self = this;

		$('body').on('click', '[data-id="unmatch-address"]', function () {
			let $this = $(this),
				id = $this.data('mappingId'),
				addressId = $this.data('addressId');

			$this.prop('disabled', true);

			return $.ajax({
				url: `/api/v1/integrations/${self.integrationId}/matches/${id}`,
				type: 'DELETE'
			}).done(function () {
				return self.RefreshMatching(addressId);
			}).fail(function (xhr) {
				$this.prop('disabled', false);
				swal(
					'Oops...',
					'Could not unmatch address:<br>' + xhr.responseJSON.error,
					'error'
				);
			});
		});
	}

	unlockMapping () {
		let self = this;

		$('body').on('click', '[data-id="unlock-mapping"]', function () {
			let $this = $(this),
				mappingId = $this.data('mapping-id'),
				addressId = $this.data('address-id')
			return $.ajax({
				url: `/api/v1/integrations/${self.integrationId}/matches/${mappingId}/unlock`,
				type: 'PATCH'
			}).done(function () {
				return self.RefreshMatching(addressId)
					.always(function () {
						$this.prop('disabled', false);
					});
			}).fail(function () {
				swal(
					'Oops...',
					'Could not unlock mapping!',
					'error'
				);
			});
		})
	}

	DuplicateAddress()
	{
		$('body').on('click', '[data-id="duplicate-address"]', function () {
			let $this = $(this),
				integrationAddressId = $this.data('integrationAddressId');

			$this.prop('disabled', true);

			return $.ajax({
				url: `/api/v1/integrations/address/${integrationAddressId}/duplicate`,
				type: 'POST'
			}).done(function () {
				window.location.reload();
			}).fail(function () {
				swal(
					'Oops...',
					'Could not duplicate address!<br><small><i>(has it already been duplicated?)</i></small>',
					'error'
				)
			});
		});
	}

	GetDoctors () {
		let self = this;

		$('body').on('click', '[data-type-action="match-doctor"]', function () {
			let $this = $(this);
			let $parentPanel = $this.parents('[data-id="parent"]').first();

			let $doctorSelectBox = $parentPanel.find('[data-id="choose-doctor"]');
			let $addressSelectBox = $parentPanel.find('[data-id="choose-doctor-address"]');
			let $servicesSelectBox = $parentPanel.find('[data-id="choose-services"]');
			let $notificationsSelect = $parentPanel.find('[data-id="choose-notifications"]');

			self._SelectInit($doctorSelectBox, []);
			self._SelectInit($addressSelectBox, []);
			self._SelectInit($servicesSelectBox, []);
			self._SelectInit($notificationsSelect, []);

			self._GetDoctorsAjaxCall($doctorSelectBox, $addressSelectBox, $servicesSelectBox, $notificationsSelect);
		});
	}

	SelectAllServices () {
		$('[data-id=select-all-services]').on('click', function () {
			let $this = $(this);
			let $servicesSelect = $this.closest('[data-id=doctor-panel').find('[data-id="choose-services"]');

			$servicesSelect.find('option').prop('selected', true);
			$servicesSelect.trigger('change');
		});
	}
}
