import swal from 'sweetalert2';

export default class CCImporter {
	constructor () {
		this.resultsIndex = [];
		this.updateStatusButton = $('button.update-status');

		this.handleSearchInputEvents = this.handleSearchInputEvents.bind(this);
		this.handleStatusUpdateClick = this.handleStatusUpdateClick.bind(this);
		this.handleNavigatorClick = this.handleNavigatorClick.bind(this);

		this.indexResults();
		this.registerHandlers();
		this.initIvrModal();
	}

	registerHandlers () {
		$('#search-input').on('keyup', this.handleSearchInputEvents);
		$('#upload-button').on('click', this.handleUploadButtonClick);
		$('#upload-ivr-button').on('click', this.handleUploadButtonClick);
		$('#subscribers_csv_csv').on('change', this.handleFileInputChange);
		$('#ivr_subscribers_csv_csv').on('change', this.handleIVRFileInputChange);
		this.updateStatusButton.on('click', this.handleStatusUpdateClick);
		$('#navigator > li').on('click', this.handleNavigatorClick)
	}

	initIvrModal () {
		let modalIvr = $("#modal-ivr");
		if (modalIvr) {
			modalIvr.modal('show');
		}
	}

	handleSearchInputEvents (event) {
		let input = $(event.target);
		this.filterResults(input.val());
	}

	handleUploadButtonClick (event) {
		let button = $(event.target);
		button.siblings('form').find('input').click();
	}

	handleFileInputChange (event) {
		let input = $(event.target);
		if (input.val().length > 0 && window.confirm('Are you sure that you want to upload selected file?')) {
			input.closest('form').submit();
		} else {
			input.val('');
		}
	}

	handleIVRFileInputChange (event) {
		let input = $(event.target);
		input.closest('form').submit();
	}

	filterResults (query) {
		const normalizedQuery = this.normalizeString(query);
		let rowSeeAll = $('.row-see-all');
		if (normalizedQuery === '') {
			rowSeeAll.removeClass('hidden');
			this.showAllRows();
			return;
		}

		rowSeeAll.addClass('hidden');

		for (let tableIdx in this.resultsIndex) {
			if (!Object.prototype.hasOwnProperty.call(this.resultsIndex, tableIdx)) {
				continue;
			}

			for (let rowIdx in this.resultsIndex[tableIdx]) {
				if (!Object.prototype.hasOwnProperty.call(this.resultsIndex[tableIdx], rowIdx)) {
					continue;
				}

				if (-1 === this.resultsIndex[tableIdx][rowIdx].indexOf(normalizedQuery)) {
					this.hideImportRow(rowIdx, tableIdx);
				} else {
					this.showImportRow(rowIdx, tableIdx);
				}
			}
		}
	}

	normalizeString (string) {
		return string.trim().toLowerCase();
	}

	indexResults () {
		for (let table of $('.panel-table')) {
			const tableIdx = table.dataset.tableidx;
			this.resultsIndex[tableIdx] = [];

			for (let importRow of $(`[data-tableIdx=${tableIdx}] .import-row`)) {
				this.resultsIndex[tableIdx][importRow.dataset.rowidx] = this.normalizeString($(importRow).find('.doctor').text());
			}
		}
	}

	showAllRows () {
		$('.import-row').removeClass('import-row--hidden');
	}

	hideImportRow (rowIdx, tableIdx) {
		$(`[data-tableIdx=${tableIdx}] .import-row[data-rowIdx=${rowIdx}]`).addClass('import-row--hidden');
	}

	showImportRow (rowIdx, tableIdx) {
		$(`[data-tableIdx=${tableIdx}] .import-row[data-rowIdx=${rowIdx}]`).removeClass('import-row--hidden');
	}

	handleStatusUpdateClick (event) {
		$.ajax(this.updateStatusButton.data('url'), {method: 'GET'})
			.done(() => {
				swal(
					'Success!',
					'Update status has been started.',
					'success'
				)
			})
			.fail(() => {
				swal(
					'Oops...',
					'Something went wrong!',
					'error'
				)
			});
	}

	handleNavigatorClick (event) {
		const clickedButton = $(event.target);
		if (clickedButton.hasClass('active')) {
			return null;
		}

		$('#navigator li').removeClass('active');
		clickedButton.addClass('active');
		$('.panel-box.form-wrapper').removeClass('active');
		$(`.panel-box.${clickedButton.data('form')}`).addClass('active')
	}
}
