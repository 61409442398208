export default class Panel {
	constructor () {
		this.handleBurgerClick = this.handleBurgerClick.bind(this);
		this.handleDocumentClick = this.handleDocumentClick.bind(this);
		this.handleContainerWidthSettingChange = this.handleContainerWidthSettingChange.bind(this);
		this.handleSeeAllClick = this.handleSeeAllClick.bind(this);
		this.handleSeeLessClick = this.handleSeeLessClick.bind(this);

		this.registerHandlers();
		this.initLayoutSettings();
	}

	registerHandlers () {
		$('.burger').on('click', this.handleBurgerClick);
		$('.layout-settings i').on('click', this.handleLayoutSettingsIconClick);
		$('#option-container-width input').on('change', this.handleContainerWidthSettingChange);
		$(document).on('click', this.handleDocumentClick);
		$('.row-see-all').on('click', this.handleSeeAllClick);
		$('.row-see-less').on('click', this.handleSeeLessClick);
	}

	initLayoutSettings () {
		const maxWidth = $(window).width() - 40;
		$('#option-container-width input')[0].max = maxWidth;

		let storedWidth = window.localStorage.getItem('containerWidth');
		if(storedWidth !== null) {
			this.setContainerWidth(storedWidth);
		} else {
			this.setContainerWidth(maxWidth)
		}
	}

	handleBurgerClick () {
		if ($('.navigation .burger').hasClass('burger--active')) {
			this.hideNavMenu();
		} else {
			this.expandNavMenu();
		}
	}

	handleDocumentClick (event) {
		let clicked = $(event.target);
		if (clicked.closest('.navigation').length === 0) {
			this.hideNavMenu();
		}
		if (clicked.closest('.layout-settings').length === 0) {
			this.hideLayoutSettings();
		}
	}

	handleLayoutSettingsIconClick () {
		$('.layout-settings .settings-expand').slideToggle('fast');
	}

	handleContainerWidthSettingChange (event) {
		this.setContainerWidth(event.target.value);
	}

	handleSeeAllClick (event) {
		let clicked = $(event.target);
		clicked.addClass('hidden');
	}

	handleSeeLessClick (event) {
		let clicked = $(event.target);
		clicked.siblings('.row-see-all').removeClass('hidden');
	}

	expandNavMenu () {
		$('.navigation .burger').addClass('burger--active');
		$('.navigation .menu').addClass('menu--expanded');
	}

	hideNavMenu () {
		$('.navigation .burger').removeClass('burger--active');
		$('.navigation .menu').removeClass('menu--expanded');
	}

	hideLayoutSettings () {
		$('.layout-settings .settings-expand').slideUp('fast');
	}

	setContainerWidth (width) {
		const parsedWidth = parseInt(width);
		$('#option-container-width span').text(parsedWidth);
		$('#option-container-width input').val(parsedWidth);
		$('.panel-header').css('max-width', parsedWidth).css('margin-left', 'auto').css('margin-right', 'auto');
		$('.panel-box').css('max-width', parsedWidth).css('margin-left', 'auto').css('margin-right', 'auto');
		$('.panel-container').css('max-width', parsedWidth).css('margin-left', 'auto').css('margin-right', 'auto');
		$('.panel-top').css('padding-left', `calc((100% - ${parsedWidth}px) / 2)`).css('padding-right', `calc((100% - ${parsedWidth}px) / 2)`);
		window.localStorage.setItem('containerWidth', parsedWidth);
	}
}
