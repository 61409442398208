export default class IntegrationsList {
	constructor () {
		this.resultsIndex = [];

		this.handleSelectOptionClick = this.handleSelectOptionClick.bind(this);
		this.handleSearchInputEvents = this.handleSearchInputEvents.bind(this);
		this.handleResetButtonClick = this.handleResetButtonClick.bind(this);
		this.handleFilterInputClick = this.handleFilterInputClick.bind(this);
		this.handleDocumentClick = this.handleDocumentClick.bind(this);
		this.handleIntegrationRowClick = this.handleIntegrationRowClick.bind(this);
		this.refreshWithFilters = this.refreshWithFilters.bind(this);
		this.handleSwitchClick = this.handleSwitchClick.bind(this);
		this.handleRestoreButtonClick = this.handleRestoreButtonClick.bind(this);
		this.handleUnlockConfirmButtonClick = this.handleUnlockConfirmButtonClick.bind(this);

		this.registerHandlers();
		this.updateFiltersState();
		this.indexResults();
		this.initTooltips();
	}

	registerHandlers () {
		$('.filter-select-option').on('click', this.handleSelectOptionClick);
		$('#search-input').on('keyup', this.handleSearchInputEvents);
		$('.filter-input').on('click', this.handleFilterInputClick);
		$('.reset-btn').on('click', this.handleResetButtonClick);
		$('#apply-status-filter-btn').on('click', this.refreshWithFilters);
		$('.integration-row').on('click', this.handleIntegrationRowClick);
		$('.unlock-btn').on('click', this.handleUnlockButtonClick);
		$('#unlock-confirm-btn').on('click', this.handleUnlockConfirmButtonClick);
		$('.integration-status .switch').on('click', this.handleSwitchClick);
		$('.restore-btn').on('click', this.handleRestoreButtonClick);
	}

	handleSelectOptionClick (event) {
		let clicked = $(event.target);
		this.selectSingleOption(clicked);
	}

	handleFilterInputClick (event) {
		let clicked = $(event.target);
		this.toggleSelect(clicked.closest('.filter-select'));
	}

	handleResetButtonClick (event) {
		let clicked = $(event.target);
		let filter = clicked.closest('.filter');

		this.clearFilter(filter);
		this.selectSingleOption(filter.find('.filter-select-option[data-default=1]'));
		filter.find('input:checked').prop('checked', false);
		filter.find('input[data-default=1]').prop('checked', true);
	}

	handleDocumentClick (event) {
		let clicked = $(event.target);
		if (clicked.closest('.filter-select--expanded').length === 0) {
			this.hideSelect($('.filter-select--expanded'));
		}
	}

	handleSearchInputEvents (event) {
		let input = $(event.target);
		this.filterResults(input.val());
	}

	handleIntegrationRowClick (event) {
		let clicked = $(event.target);
		if (clicked.closest('.integration-status').length !== 0) {
			return;
		}

		let integrationRow = clicked.closest('.integration-row');

		window.location.href = integrationRow.data('href');
	}

	handleUnlockButtonClick (event) {
		let clicked = $(event.target);
		const integrationId = clicked.closest('.integration-row').data('id');
		const unlockUrl = clicked.data('href');
		$('#unlock-modal .value-holder').data('integration-id', integrationId);
		$('#unlock-confirm-btn').data('href', unlockUrl);
		$('#unlock-modal').modal('show');
	}

	handleUnlockConfirmButtonClick () {
		this.unlockIntegration($('#unlock-modal .value-holder').data('integration-id'));
	}

	handleSwitchClick (event) {
		let element = $(event.target).closest('.switch');
		let integrationId = element.closest('.integration-row').data('id');
		if (element.hasClass('switch--on')) {
			this.disableIntegration(integrationId);
		} else {
			this.enableIntegration(integrationId);
		}
	}

	handleRestoreButtonClick (event) {
		let clicked = $(event.target);
		this.disableIntegration(clicked.closest('.integration-row').data('id'));
	}

	updateFiltersState () {
		const statuses = $('.status-filter:checked').map(function (idx, item) {
			return item.dataset.status;
		});

		const software = $('.software-option.filter-select-option--selected').data('value');
		const health = $('.health-option.filter-select-option--selected').data('value');

		this.filters = {
			statuses: statuses,
			software: software,
			health: health,
		};

		this.updateStatusSelect();
		this.updateSoftwareSelect();
		this.updateHealthSelect();
	}

	updateStatusSelect () {
		let input = $('#filter-status .filter-input');
		const statusesCount = this.filters.statuses.length;
		const template = statusesCount === 0 ? input.data('placeholder') : `${input.data('placeholder')} (${statusesCount})`;

		input.text(template);
	}

	updateSoftwareSelect () {
		let input = $('#filter-software .filter-input');
		const software = this.filters.software;
		const template = software ? software : input.data('placeholder');

		input.text(template);
	}

	updateHealthSelect () {
		let input = $('#filter-health .filter-input'), template;
		const health = this.filters.health;
		switch (health) {
			case true:
				template = 'Healthy';
				break;
			case false:
				template = 'Unhealthy';
				break;
			default:
				template = input.data('placeholder');
		}

		input.text(template);
	}

	refreshWithFilters () {
		this.updateFiltersState();

		let queryParams = [];

		for (let status of this.filters.statuses.toArray()) {
			queryParams.push('statuses[]=' + encodeURIComponent(status));
		}

		if (typeof this.filters.software !== 'undefined') {
			queryParams.push('software=' + encodeURIComponent(this.filters.software))
		}
		if (typeof this.filters.health !== 'undefined') {
			queryParams.push(`health=${true === this.filters.health ? 1 : 0}`);
		}

		window.location.href = `?${queryParams.join('&')}`;
	}

	expandSelect (element) {
		element.addClass('filter-select--expanded');
		element.find('.filter-expand').slideDown('fast');
		document.addEventListener('click', this.handleDocumentClick);
	}

	hideSelect (element) {
		element.removeClass('filter-select--expanded');
		element.find('.filter-expand').slideUp('fast');
		document.removeEventListener('click', this.handleDocumentClick);
		this.updateFiltersState();
	}

	toggleSelect (element) {
		if (!element.hasClass('filter-select')) {
			console.debug('Toggled element does not have .filter-select class!', element);
			return;
		}

		if (element.hasClass('filter-select--expanded')) {
			this.hideSelect(element)
		} else {
			this.expandSelect(element)
		}
	}

	selectSingleOption (element) {
		if (!element.hasClass('filter-select-option')) {
			console.debug('Element does not have .filter-select-option class!', element);
			return;
		}

		element.siblings()
			.removeClass('filter-select-option--selected')
			.removeClass('filter-select-option--before-selected')
			.removeClass('filter-select-option--after-selected');
		element.prev().addClass('filter-select-option--before-selected');
		element.next().addClass('filter-select-option--after-selected');

		element.addClass('filter-select-option--selected');
	}

	clearFilter (element) {
		if (!element.hasClass('filter')) {
			console.debug('Element does not have .filter-select-option class!', element);
			return;
		}

		element.find('.filter-select-option')
			.removeClass('filter-select-option--selected')
			.removeClass('filter-select-option--before-selected')
			.removeClass('filter-select-option--after-selected');
	}

	indexResults () {
		for (let integrationRow of $('.integration-row')) {
			this.resultsIndex[integrationRow.dataset.rowidx] = this.normalizeString($(integrationRow).find('.integration-name').text());
		}
	}

	initTooltips () {
		$('.health-indicator--unhealthy').tooltip();
	}

	filterResults (query) {
		const normalizedQuery = this.normalizeString(query);
		if (normalizedQuery === '') {
			this.showAllIntegrations();
		}

		for (let index in this.resultsIndex) {
			if (!Object.prototype.hasOwnProperty.call(this.resultsIndex, index)) {
				continue;
			}

			if (-1 === this.resultsIndex[index].indexOf(normalizedQuery)) {
				this.hideIntegrationRow(index);
			} else {
				this.showIntegrationRow(index);
			}
		}
	}

	normalizeString (string) {
		return string.trim().toLowerCase();
	}

	hideIntegrationRow (rowIdx) {
		$(`.integration-row[data-rowIdx=${rowIdx}]`).addClass('integration-row--hidden');
	}

	showIntegrationRow (rowIdx) {
		$(`.integration-row[data-rowIdx=${rowIdx}]`).removeClass('integration-row--hidden');
	}

	showAllIntegrations () {
		$('.integration-row--hidden').removeClass('integration-row--hidden');
	}

	enableIntegration (integrationId) {
		let integrationRow = $(`.integration-row[data-id=${integrationId}]`);
		let switchElement = integrationRow.find('.switch');
		switchElement.addClass('switch--on');
		switchElement.removeClass('switch--off');
		$.ajax({
			url: integrationRow.find('.integration-status').data('href-enable'),
			type: 'PATCH',
			success: () => {
				integrationRow
					.addClass('integration-row--enabled')
					.removeClass('integration-row--disabled')
					.removeClass('integration-row--deleted');
				this.ajaxInvalidateCache();
			},
			error: function () {
				switchElement.addClass('switch--off');
				switchElement.removeClass('switch--on');
				window.alert("An error occurred while trying to enable integration.");
			}
		});
	}

	disableIntegration (integrationId) {
		let integrationRow = $(`.integration-row[data-id=${integrationId}]`);
		let switchElement = integrationRow.find('.switch');
		switchElement.addClass('switch--off');
		switchElement.removeClass('switch--on');
		$.ajax({
			url: integrationRow.find('.integration-status').data('href-disable'),
			type: 'PATCH',
			success: () => {
				integrationRow
					.addClass('integration-row--disabled')
					.removeClass('integration-row--enabled')
					.removeClass('integration-row--deleted');
				this.ajaxInvalidateCache();
			},
			error: function () {
				switchElement.addClass('switch--on');
				switchElement.removeClass('switch--off');
				window.alert("An error occurred while trying to disable integration.");
			}
		});
	}

	unlockIntegration (integrationId) {
		let integrationRow = $(`.integration-row[data-id=${integrationId}]`);
		$('#unlock-modal').modal('hide');
		$.ajax({
			url: $('#unlock-confirm-btn').data('href'),
			type: 'PATCH',
			success: () => {
				integrationRow.removeClass('integration-row--locked');
			},
			error: function () {
				window.alert("An error occurred while trying to unlock the integration.");
			}
		})
	}

	ajaxInvalidateCache () {
		$.ajax({
			url: $('#refresh-form').data('href-ajax'),
			type: 'POST',
		});
	}
}
