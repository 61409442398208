import '../../../../../node_modules/select2/dist/js/select2.full';
import '../../../../../node_modules/sweetalert2/dist/sweetalert2.all.min';
import Notifications from './integrationNotifications';
const swal = require('sweetalert2');
const MultipleConfigKeys = ['tt-area-ids', 'bizmed2-branch-ids', 'helios-tenant-ids', 'samedi-practice-ids', 'ivoris-auth-data', 'tt-member-auth-data'];

export default class IntegrationEdit {
	constructor (integrationId) {
		this.$facilitySelect = $('[data-id="ddl-facility"]');
		this.$form = $('[name="edit-integration-form"]').first();
		this.integrationId = integrationId;

		new Notifications(integrationId);

		$('[data-id="add-conf-btn"]').on('click', function () {
			IntegrationEdit.createNewConfigurationForm();
		});

		$('[data-id="remove-multiple-config-value"]').on('click', function () {
			$(this).parent('.value-item').remove();
		});

		$('[data-id="add-multiple-config-input"]').on('click', function () {
			IntegrationEdit.renderMultipleConfigurationKeyInput($(this).closest('[data-id=configuration-form]'));
		});

		if (this.$form.length > 0)
		{
			this.SetupFacilitySelect();
			this.HandleSubmit();
			this.addConfiguration();
			this.removeConfiguration();
			this.addFacility();
			this.removeFacility();
		}
	}

	/**
	 * Select2 Init
	 */
	SetupFacilitySelect () {

		this.$facilitySelect.select2({
			cache: true,
			width: '100%',
			minimumInputLength: 3,
			ajax: {
				delay: 500,
				data: null,
				url: function (params) {
					return `/api/v1/external/facilities/${encodeURIComponent(params.term)}`
				},
				processResults: function (results) {
					return {results};
				}
			},

			templateResult: function (data) {
				if (!data.id) {
					return 'loading...';
				}

				return `${data.id} ${data.name || ''} ${data.street || ''} ${data.city || ''}`;
			},

			templateSelection: function (data) {
				return `${data.id} ${data.name || data.text}`;
			}
		}).trigger('change');
	}

	/**
	 * Collecting All data
	 */
	_GetFromData () {
		let self = this;
		return {
			name: this.$form.find('[name="name"]').val(),
			type: this.$form.find('[name="webhook-type"]').val(),
			url: this.$form.find('[name="url"]').val(),
			user: this.$form.find('[name="user"]').val(),
			secret: this.$form.find('[name="secret"]').val(),
			token: this.$form.find('[name="token"]').val(),
			status: self.$form.data('integration-status'),
			workers: self._GetWorkers(),
			note: this.$form.find('[name="note"]').val(),
			supportEmail: this.$form.find('[name="support-email"]').val(),
			pingUrl: this.$form.find('[name="ping-url"]').val()
		};
	}

	/**
	 * Collecting Workers Data
	 */
	_GetWorkers () {
		return [
			{
				type: "fetch-calendar",
				interval: this.$form.find('[name="fetch-calendar-interval"]').val(),
				timeout: this.$form.find('[name="fetch-calendar-timeout"]').val(),
				grace: this.$form.find('[name="fetch-calendar-grace"]').val(),

			},
			{
				type: "import-doctors",
				interval: this.$form.find('[name="import-doctors-interval"]').val(),
				timeout: this.$form.find('[name="import-doctors-timeout"]').val(),
				grace: this.$form.find('[name="import-doctors-grace"]').val(),
			},
			{
				type: "check-webhook",
				interval: this.$form.find('[name="check-webhook-interval"]').val(),
				timeout: this.$form.find('[name="check-webhook-timeout"]').val(),
				grace: this.$form.find('[name="check-webhook-grace"]').val(),
			}
		]
	}

	/**
	 * Collecting Configuration Data
	 */
	static GetConfigurationData ($item) {
		let formValue = null;
		if (MultipleConfigKeys.includes($item.find('select[name="config-name"]').val())) {
			formValue = $item.find('input[name="config-value[]"]').map(function () {
				return $(this).val();
			}).get();
		} else  {
			formValue = $item.find('input[name="config-value"]').val();
		}

		return {
			name: $item.find('select[name="config-name"]').val(),
			value: formValue,
			type: $item.find('select[name="config-type"]').val(),
			is_specification: $item.find('input[name="is-specification"]').prop('checked')
		};
	}

	/**
	 * Collecting Facilities Data
	 */
	_GetFacilitySelections () {
		let selections = this.$facilitySelect.select2('data');

		return selections.map(function (item) {
			return {
				id: item.id,
				name: item.name || item.text.trim()
			}
		});
	}

	/**
	 * Send data
	 */
	HandleSubmit () {
		const self = this;

		this.$form.on('submit', (e) => {
			e.preventDefault();

			self._SendRequest();
		});
	}

	_SendRequest () {
		const self = this;

		$.ajax(
			`/api/v1/integrations/${this.integrationId}`,
			{
				method: 'PATCH',
				contentType: 'application/json',
				dataType: 'json',
				data: JSON.stringify(self._GetFromData())
			}
		).done(() => {
			swal('Success!', 'Integration data has been updated.', 'success')
		}).fail(() => {
			swal(
				'Oops...',
				'Something went wrong!',
				'error'
			)
		});
	}

	static createNewConfigurationForm () {
		let $editConfigurationPanel = $('[data-id="integration-config"]'),
			template = $('[data-id="config-item"]').has('input[name="config-value"]').first().clone().html(),
			$newRow = $(document.createElement('div'))
				.addClass('row offset-top-1')
				.attr('data-id', 'config-item')
				.html(template);

		$newRow.find('select[name="config-name"]').val(null).removeAttr('disabled');
		$newRow.find('input[name="config-value"]').val(null);
		$newRow.find('select[name="config-type"]').val(null);
		$newRow.find('input[name="is-specification"]').prop("checked", false);
		$newRow.find('[data-id="remove-conf-btn"]').removeAttr('data-action');

		$editConfigurationPanel.append($newRow);
		IntegrationEdit.appendSelectConfigKeyListener($newRow);
	}

	static appendSelectConfigKeyListener (container) {
		container.find('select[name="config-name"]').on('change', function () {
			let type = $(this).children('option:selected').data('type');
			let defaultValue = $(this).children('option:selected').data('value');
			let applyToAddresses = $(this).children('option:selected').data('apply-to-addresses');
			const form = $(this).closest('[data-id=configuration-form]');
			form.find('select[name="config-type"]').val(type);
			form.find('input[name="is-specification"]').prop("checked", !!applyToAddresses);

			if (!MultipleConfigKeys.includes($(this).children('option:selected').val())) {
				let input = form.find('input[name=config-value]');
				if (input.length === 0) {
					IntegrationEdit.renderSingleConfigurationKeyInput(form);
				}

				input.val(defaultValue.toString() || null);
				IntegrationEdit.removeAddButton(form);
				IntegrationEdit.removeMultipleConfigurationKeyInput(form);
			} else {
				IntegrationEdit.removeMultipleConfigurationKeyInput(form);
				form.find('input[name=config-value]').remove();
				IntegrationEdit.showAddButton(form);
				IntegrationEdit.renderMultipleConfigurationKeyInput(form);
			}
		});
	}

	/**
	 * Adding configuration element
	 */
	addConfiguration () {
		$('[data-id="integration-config"]').on('submit', '[data-id="configuration-form"]', function (e) {
			e.preventDefault();

			let $this = $(this),
				$item = $this.parent(),
				configuration = IntegrationEdit.GetConfigurationData($item);

			if(!(configuration.name && configuration.value && configuration.type))
			{
				swal(
					'Oops...',
					'Please fill every field on configuration form!',
					'error'
				);
			}

			const url = MultipleConfigKeys.includes(configuration.name) ?
				$(this).find('[data-id="update-conf-btn"]').data('multiple-action'):
				$(this).find('[data-id="update-conf-btn"]').data('single-action');

			$.ajax(
				url,
				{
					method: 'PUT',
					contentType: 'application/json',
					data: JSON.stringify(configuration),
					dataType: 'text'
				}
			).done((data, textStatus, xhr) => {
				let resourceUrl = xhr.getResponseHeader('Location');

				$item
					.find('[data-id="remove-conf-btn"]').attr('data-action', resourceUrl)
					.end()
					.find('select[name="config-name"]').prop('disabled', true);

			}).fail((xhr) => {
				if (xhr.status === 400) {
					const response = JSON.parse(xhr.responseText);
					swal(
						'Oops...',
						response.error || 'Something went wrong!',
						'error'
					)

					return;
				}

				swal(
					'Oops...',
					'Something went wrong!',
					'error'
				)
			});
		});
	}

	/**
	 * Removing configuration element
	 */
	removeConfiguration () {
		$('[data-id="integration-config"]').on('click', '[data-id="remove-conf-btn"]', function (e) {
			e.preventDefault();

			let $this = $(this);

			if(!$this.data('action'))
			{
				IntegrationEdit.removeConfigurationContainer($this.parents('[data-id="config-item"]'));
				return;
			}

			$.ajax($this.data('action'), { method: 'DELETE' })
				.done(() => {
					IntegrationEdit.removeConfigurationContainer($this.parents('[data-id="config-item"]'));
				})
				.fail(() => {
					swal(
						'Oops...',
						'Could not delete configuration!',
						'error'
					)
				});
		});
	}

	static removeConfigurationContainer($item)
	{
		if($item.siblings().length === 0)
		{
			IntegrationEdit.createNewConfigurationForm();
		}

		$item.detach();
	}

	/**
	 * Adding new facility
	 */
	addFacility () {
		let self = this;

		this.$form.on('select2:select', '[data-id="ddl-facility"]', function () {
			let url = $(this).data('action'),
				data = self._GetFacilitySelections().pop();

			$.ajax(url, {
				method: 'POST',
				contentType: 'application/json',
				data: JSON.stringify(data),
				dataType: 'json'
			}).done(function () {

				self.addFacilityTemplate(data.id, data.name);

			}).fail(function (xhr) {
				const messages = self._GetErrorMessagesFrom(xhr);

				swal(
					'Oops...',
					messages.length ? messages[0].message : 'Unable add facility to integration',
					'error'
				)
			});
		});
	}

	/**
	 * Removing new facility
	 */
	removeFacility () {
		this.$form.on('click', '[data-id="remove-item"]', function () {
			let $this = $(this);

			$.ajax($this.data('action'), {method: 'DELETE'})
				.done(() => {
					$this.parent().detach();
				})
				.fail(() => {
					swal(
						'Oops...',
						'Unable to remove facility from integration',
						'error'
					)
				});
		})
	}

	/**
	 * adding facility block
	 */
	addFacilityTemplate (id, name) {
		let $facilities = $('[data-id="facilities-block"]');
		const self = this;

		let template = `<div class="bg-gray-lighter pull-left padding-1 offset-right-1" data-facility-item-id="${id}">
								${id} ${name}
								<span class="offset-left-1 text-primary pointer"
									data-id="remove-item"
									data-action="/api/v1/integrations/${self.integrationId}/facilities/${id}"
								>x</span>
						</div>`;

		$facilities.append(template);
	}

	_GetErrorMessagesFrom(xhr) {
		const response = JSON.parse(xhr.responseText);
		if (response.error && response.error.exception) {
			return response.error.exception
		}

		return [];
	}

	static renderMultipleConfigurationKeyInput (container) {
		const selectTemplate = `<div class="display-flex value-item">
			<input autocomplete="off" type="text" class="form-control offset-bottom-1" name="config-value[]" required>
			<button class="btn btn-danger offset-left-1" type="button" data-id="remove-multiple-config-value">
				<i class="fa fa-minus offset-0"></i>
			</button>
		</div>`;
		container.find('.multiple-key').append(selectTemplate);

		$('[data-id="remove-multiple-config-value"]').on('click', function () {
			$(this).parent('.value-item').remove();
		});
	}

	static showAddButton (container) {
		const btn = container.find('.multiple-key .btn-primary.hide');
		btn.removeClass('hide');
		btn.on('click', function () {
			IntegrationEdit.renderMultipleConfigurationKeyInput($(this).closest('[data-id=configuration-form]'));
		})
	}

	static removeAddButton (container) {
		const btn = container.find('.multiple-key .btn-primary');
		btn.addClass('hide');
	}

	static removeMultipleConfigurationKeyInput (container) {
		container.find('.multiple-key .value-item').remove();
	}

	static renderSingleConfigurationKeyInput (container) {
		const inputTemplate = $('[data-id="config-item"] form input[name="config-value"]')
			.first()
			.clone()
			.val(null);

		container.find('.config-value').append(inputTemplate)
	}
}
