export default class AggregatedStatusPage {
	constructor () {
		this.bindEventListeners();
		this.initializeTooltips();
	}

	bindEventListeners () {
		$('.integrations-status__header').on('click', function () {
			let $integrations = $(this).parent().find('.integrations-status__integrations-rows');
			$integrations.toggleClass('expanded');
		});

		$('.integration-row__item:not(.integration-row__item--event-history)').on('click', function () {
			let $eventHistory = $(this).siblings('.integration-row__item--event-history');
			$eventHistory.toggle();
		});
	}

	initializeTooltips () {
		$('.integration-row__item--status').tooltip();
	}
}