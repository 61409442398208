import swal from "sweetalert2";

export default class StatusPage {
	constructor () {
		this.allIntegration = $(".integration-item__body");
		this.allIntegrationWithUpStatus = $(".integration-item__body .general .status.up").parent().parent();
		this.allIntegrationWithDownStatus = $(".integration-item__body .general .status.down").parent().parent();
		this.allIntegrationWithDisabledStatus = $(".integration-item__body .general .status.disabled").parent().parent();
		this.allStatusSuccessBadges = $(".integration-type__stats .badge-pill.success");
		this.allStatusDangerBadges = $(".integration-type__stats .badge-pill.danger");
		this.allStatusDisabledBadges = $(".integration-type__stats .badge-pill.disabled");
		this.allStatusBadges = $(".integration-type__stats .badge-pill");

		this.handleIntegrationTypeClick = this.handleIntegrationTypeClick.bind(this);
		this.handleIntegrationItemClick = this.handleIntegrationItemClick.bind(this);
		this.handleIntegrationStatusClick = this.handleIntegrationStatusClick.bind(this);

		this.registerHandlers();
	}

	registerHandlers() {
		$('.integration-type__name').on('click', this.handleIntegrationTypeClick);
		$('.integration-item__body > .general').on('click', this.handleIntegrationItemClick);
		$('.integration-type__stats .badge-pill').on('click', this.handleIntegrationStatusClick);
	}

	handleIntegrationTypeClick(event) {
		const integrationType = $(event.currentTarget).parent().parent();
		integrationType.toggleClass('active');
	}

	handleIntegrationItemClick (event) {
		const integrationItem = $(event.currentTarget).parent();
		integrationItem.toggleClass('active');

		const url = integrationItem.find('.general').data('chart-url');
		const uptimeChart = integrationItem.find('.uptime-chart');

		if(!uptimeChart.data('fetched') && url) {
			$.ajax(url, {method: 'GET'})
				.done((data) => {
					uptimeChart.html(data)
					uptimeChart.data('fetched', true);
					uptimeChart.find('[data-toggle="tooltip"]').tooltip();
				})
				.fail(() => {
					swal(
						'Oops...',
						'Something went wrong with Uptime chart!',
						'error'
					)
				});
		}

	}

	handleIntegrationStatusClick(event) {
		const status = $(event.currentTarget);

		if (status.hasClass('active') && !status.hasClass('disabled')) {
			this.allIntegration.show();
			this.allStatusBadges.removeClass('active');
		}
		else if (status.hasClass('danger')) {
			this.allIntegrationWithUpStatus.hide();
			this.allIntegrationWithDownStatus.show();
			this.allStatusSuccessBadges.removeClass('active');
			this.allStatusDangerBadges.addClass('active');
		}
		else if (status.hasClass('success')) {
			this.allIntegrationWithDownStatus.hide();
			this.allIntegrationWithUpStatus.show();
			this.allStatusDangerBadges.removeClass('active');
			this.allStatusSuccessBadges.addClass('active');
		}
		else if (status.hasClass('disabled')) {
			if (status.hasClass('active'))
			{
				this.allIntegrationWithDisabledStatus.hide();
				this.allStatusDisabledBadges.removeClass('active');
			} else {
				this.allIntegrationWithDisabledStatus.show();
				this.allStatusDisabledBadges.addClass('active');
			}

		}
	}
}
