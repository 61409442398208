import 'select2';

export default class MappingPanel {
	constructor () {
		this.facilitiesSelect = $('#marketplace_facilities_facilities');
		this.saveButton = $('#save-all');
		this.saveButtonOffset = this.saveButton.offset();
		this.doctorMappingPrototype = $('#doctor-mapping-prototype').data('prototype');
		this.doctorMappingIndex = 0;

		this.initFacilitiesSelect();
		this.initMarketplaceDoctorSelects();
		this.disableInputs();
		this.registerHandlers();
		this.initMappings();
		this.initAutomatches();
		this.toggleSaveButton();
		this.hideLoader();
		this.initAutomatchingModal();
	}

	initFacilitiesSelect () {
		if (this.facilitiesSelect) {
			this.facilitiesSelect.find('option').attr('selected', 'selected');

			this.facilitiesSelect.select2({
				cache: true,
				width: '100%',
				minimumInputLength: 3,
				ajax: {
					delay: 500,
					data: null,
					url: function (params) {
						return `/api/v1/external/facilities/${encodeURIComponent(params.term)}`
					},
					processResults: function (results) {
						return {results};
					}
				},

				templateResult: function (data) {
					if (!data.id) {
						return 'loading...';
					}

					return `${data.id} ${data.name || ''} ${data.street || ''} ${data.city || ''}`;
				},

				templateSelection: function (data) {
					return `${data.id} ${data.name || data.text}`;
				}
			});
		}
	}

	initMarketplaceDoctorSelects () {
		$('.doctor-mapping-field--marketplace-doctor select').select2();
	}

	initMappings () {
		$('.doctor-mapping-field--marketplace-doctor').each((index, element) => {
			let el = $(element);
			let mpDoctorId = el.data('mp-doctor-id');
			if (mpDoctorId) {
				this.confirmDoctorMapping(el.closest('.doctor-mapping-row'));
			}
		})
	}

	initAutomatches () {
		$('[data-tt-doctor-id][data-automatch]').each((index, element) => {
			let el = $(element);
			let mpDoctorId = el.data('automatch');
			let maxScore = el.data('maxScore');
			let mpDoctorContainer = el.siblings('.doctor-mapping-field--marketplace-doctor');

			mpDoctorContainer.find('select').val(mpDoctorId).trigger('change');
			mpDoctorContainer.addClass('auto-matched');
			if (maxScore) {
				mpDoctorContainer.addClass('auto-matched--max-score');
			}
		})
	}

	initAutomatchingModal () {
		let automatchModal = $('#automatch-modal');

		if (typeof automatchModal !== undefined) {
			automatchModal.modal('show');
		}
	}

	initImportDoctorsModal () {
		const modal = $('#import-doctors-modal');

		if (typeof modal !== undefined) {
			modal.modal('show');
		}
	}

	hideLoader () {
		$('.mapping-panel').removeClass('loading');
	}

	confirmDoctorMapping (doctorMappingRow) {
		let ttDoctorId = doctorMappingRow.find('.doctor-mapping-field--tuotempo-doctor').data('tt-doctor-id');
		let mpDoctorId = doctorMappingRow.find('.doctor-mapping-field--marketplace-doctor').data('mp-doctor-id');
		let mpDoctorName = doctorMappingRow.find('.doctor-mapping-field--marketplace-doctor').data('mp-doctor-name');
		let inputPlaceholder = doctorMappingRow.find('.doctor-mapping-placeholder');
		let inputs = this.doctorMappingPrototype.replace(/__name__/g, this.doctorMappingIndex);

		inputPlaceholder.html(inputs);
		inputPlaceholder.prepend(mpDoctorName);

		$(`#marketplace_tuotempo_mappings_mappings_${this.doctorMappingIndex}_marketplace_doctor_id`).val(mpDoctorId);
		$(`#marketplace_tuotempo_mappings_mappings_${this.doctorMappingIndex}_tuotempo_doctor_id`).val(ttDoctorId);

		doctorMappingRow.find('select, .select2').hide();
		inputPlaceholder.show();
		doctorMappingRow.find('.doctor-mapping-confirm').hide();
		doctorMappingRow.find('.doctor-mapping-unmap').show();

		this.doctorMappingIndex++;
	}

	unmapDoctorMapping (doctorMappingRow) {
		doctorMappingRow.find('.doctor-mapping-field--tuotempo-doctor').removeData('tt-doctor-id');
		doctorMappingRow.find('.doctor-mapping-field--marketplace-doctor').removeData('mp-doctor-id');
		doctorMappingRow.find('.doctor-mapping-placeholder').html('');
		doctorMappingRow.find('select, .select2').show();
		doctorMappingRow.find('.doctor-mapping-placeholder').hide();
		doctorMappingRow.find('.doctor-mapping-unmap').hide();

		if (doctorMappingRow.find('select').val()) {
			doctorMappingRow.find('.doctor-mapping-confirm').show();
		}
	}

	disableInputs () {
		$('.form-box--disabled').find('input, select').prop('disabled', true);
	}

	registerHandlers () {
		$('.form-edit-button').on('click', this.handleFormEditButtonClick);
		$('.doctor-mapping-field--marketplace-doctor select').on('change', event => this.handleMarketplaceDoctorSelectChange(event));
		$('.doctor-mapping-confirm').on('click', event => this.handleDoctorMappingConfirmClick(event));
		$('.doctor-mapping-unmap').on('click', event => this.handleDoctorMappingUnmapClick(event));
		$('.automatch-btn').on('click', () => this.handleAutomatchButtonClick());
		$('.import-doctors-btn').on('click', () => this.handleImportDoctorsButtonClick());
		$(window).on('scroll', () => this.handleWindowScroll());
	}

	handleFormEditButtonClick (event) {
		let container = $(event.target).closest('.form-box');
		container.removeClass('form-box--disabled');
		container.find('input, select').prop('disabled', false);
	}

	handleMarketplaceDoctorSelectChange (event) {
		let select = $(event.target);
		let container = select.parent();
		const value = select.val();

		if (value !== null) {
			select.parent().find('.doctor-mapping-confirm').show();
		} else {
			select.parent().find('.doctor-mapping-confirm').hide()
		}

		container.removeClass('auto-matched');
	}

	handleDoctorMappingConfirmClick (event) {
		let button = $(event.target);
		let mappingRow = button.closest('.doctor-mapping-row');
		let select = mappingRow.find('select');
		let selectedOption = select.find('option:selected');
		let mpDoctorField = mappingRow.find('.doctor-mapping-field--marketplace-doctor');

		mpDoctorField.data('mp-doctor-id', selectedOption.val());
		mpDoctorField.data('mp-doctor-name', selectedOption.text());

		this.confirmDoctorMapping(mappingRow);
	}

	handleDoctorMappingUnmapClick (event) {
		let button = $(event.target);
		let mappingRow = button.closest('.doctor-mapping-row');

		this.unmapDoctorMapping(mappingRow);
	}

	handleWindowScroll () {
		this.toggleSaveButton();
	}

	handleAutomatchButtonClick () {
		$('#automatch-form').submit();
	}

	handleImportDoctorsButtonClick () {
		this.initImportDoctorsModal();
	}

	toggleSaveButton () {
		let scrollTop = $(window).scrollTop() + $(window).height();
		let saveButtonOffset = this.saveButtonOffset.top - this.saveButton.height() - 4;

		if (saveButtonOffset > scrollTop) {
			this.saveButton.addClass('fixed');
		} else {
			this.saveButton.removeClass('fixed');
		}
	}
}
