export default class StatusPage {
	constructor (integrationId) {
		this.integrationId = integrationId;
		this.fetchCalendars(this.integrationId);
	}


	fetchCalendars (integrationId) {
		$('[data-action=refresh-calendars]').click(function () {
			let $successModal = $("#modal-info");
			let $errorModal = $("#modal-error");

			return $.ajax({
				url: `/api/v1/integrations/${integrationId}/matches/sync`,
				method: 'PUT',
				statusCode: {
					204: function (xhr) {
						$successModal.modal('show');
					},
					403: function (xhr) {
						$errorModal.modal('show');
					},
					500: function (xhr) {
						alert('Internal error. Please try again');
					}
				}
			});
		});
	}
}