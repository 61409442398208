global.$ = global.jQuery = require("jquery");

// CSS
require('../css/main.scss');

require('bootstrap/js/transition');
require('bootstrap/js/alert');
require('bootstrap/js/button');
require('bootstrap/js/carousel');
require('bootstrap/js/collapse');
require('bootstrap/js/dropdown');
require('bootstrap/js/modal');
require('bootstrap/js/tooltip');
require('bootstrap/js/popover');
require('bootstrap/js/scrollspy');
require('bootstrap/js/tab');
require('bootstrap/js/affix');

// JS
require('../js/modules/module.js');


const PAGES = {
	INTEGRATION_EDIT: 'edit',
	INTEGRATION_MATCHING: 'matching',
	INTEGRATION_LIST: 'integrations-list',
	INTEGRATION_CREATE: 'create',
	HOME: 'home',
	INTEGRATION_IMPORT: 'import',
	STATUS_PAGE: 'status-page',
	COMMUNIQUE_CREATE: 'communique-create',
	COMMUNIQUE_LIST: 'communique-list',
	AGGREGATED_STATUS_PAGE: 'aggregated-status-page',
	TUOTEMPO_MAPPING: 'tuotempo-mapping',
	TUOTEMPO_MAPPING_LIST: 'tuotempo-mapping-list',
	PANEL_INTEGRATIONS_LIST: 'panel-integrations-list',
	CALL_CENTER_PANEL_IMPORTER: 'panel-importer',
	PANEL_STATUS_PAGE: 'panel-status-page'
};
const PAGE = document.querySelector('body').dataset.page;
const INTEGRATION_ID = document.querySelector('body').dataset.integrationId;

$('[data-toggle="popover"]').popover();

switch (PAGE) {
	case PAGES.INTEGRATION_EDIT:
		const IntegrationEdit = require('./modules/editIntegration').default;

		new IntegrationEdit(INTEGRATION_ID);

		break;
	case PAGES.INTEGRATION_MATCHING:

		const IntegrationsMatcher = require('./modules/integrationMatcher').default;
		const IntegrationPage = require('./modules/integrationPage').default;
		const ServiceMapper = require('./modules/serviceMapper').default;
		const CalendarSettings = require('./modules/calendarSettings').default;
		const AddressServiceManager = require('./modules/addressServiceManager').default;
		const MappingVisitInterval = require('./modules/mappingVisitInterval').default;

		new IntegrationPage(INTEGRATION_ID);
		new IntegrationsMatcher(INTEGRATION_ID);
		new ServiceMapper(INTEGRATION_ID);
		new CalendarSettings(INTEGRATION_ID);
		new AddressServiceManager(INTEGRATION_ID);
		new MappingVisitInterval(INTEGRATION_ID);

		break;
	case PAGES.INTEGRATION_LIST:
		const IntegrationsList = require('./modules/integrationsList').default;

		new IntegrationsList();
		break;
	case PAGES.INTEGRATION_CREATE:
		const IntegrationCreate = require('./modules/createIntegration').default;

		new IntegrationCreate();
		break;

	case PAGES.STATUS_PAGE:
		const StatusPage = require('./modules/statusPage').default;

		new StatusPage(INTEGRATION_ID);
		break;
	case PAGES.COMMUNIQUE_CREATE:
		const CommuniqueCreate = require('./modules/communique/communiqueCreate').default;
		new CommuniqueCreate();
		break;
	case PAGES.COMMUNIQUE_LIST:
		const CommuniqueList = require('./modules/communique/communiqueList').default;
		new CommuniqueList();
		break;
	case PAGES.AGGREGATED_STATUS_PAGE:
		const AggregatedStatusPage = require('./modules/aggregatedStatusPage').default;
		new AggregatedStatusPage();
		break;
	case PAGES.TUOTEMPO_MAPPING:
		const MappingPanel = require('./modules/tuotempo/mappingPanel').default;
		new MappingPanel();
		break;
	case PAGES.TUOTEMPO_MAPPING_LIST:
		const MappingList = require('./modules/tuotempo/mappingList').default;
		new MappingList();
		break;
	case PAGES.PANEL_INTEGRATIONS_LIST:
		const PanelIntegrationsList = require('./modules/panel/integrationsList').default;
		new PanelIntegrationsList();
		break;
	case PAGES.PANEL_STATUS_PAGE:
		const PanelStatusPage = require('./modules/statusPage/statusPage').default;
		new PanelStatusPage();
		break;
	case PAGES.CALL_CENTER_PANEL_IMPORTER:
		const CCImporter = require('./modules/panel/CCImporter').default;
		new CCImporter();
		break;
}

if(document.querySelector('body').dataset.panel) {
	const Panel = require('./modules/panel/panel').default;
	new Panel();
}
