import '../../../../../node_modules/select2/dist/js/select2.min';
const swal = require('sweetalert2');

export default class ServiceMapper {
	constructor(integrationId) {
		this.integrationId = integrationId;

		this.setupPanels();
	}

	setupPanels() {
		const self = this;

		$(document.body)
			.on('show.bs.collapse', '[data-id="collapsable-doctor-panel"]', function () {

				self.fetchForm($(this));

			});
	}

	fetchForm($panel) {

		const self = this;

		let $serviceContainer = $panel.find('[data-id="service-mapping-form-container"]');
		let addressMappingId = $panel.data('addressMappingId');

		if (0 === $serviceContainer.length)
		{
			return;
		}

		$.ajax({
			'url': `/integrations/${self.integrationId}/service-mappings/${addressMappingId}/form`,
			'dataType': 'html',
			'method': 'GET'
		}).done(function (data) {

			$serviceContainer.html(data);

			self.setupButtons($panel, $serviceContainer);

			ServiceMapper.InitialiseDropDowns($serviceContainer);

		}).fail(function (xhr) {
			if(409 === xhr.status)
			{
				swal(
					'Oops...',
					'Service was already matched!',
					'warning'
				);
			}
			else
			{
				swal(
					'Oops...',
					'An error occured while mapping services!',
					'error'
				)
			}
		});
	}

	setupButtons($panel, $container) {

		const self = this;

		$container
			.find('[data-id="btn-map-service"]')
			.on('click', function (e) {
				e.preventDefault();

				const $this = $(this);

				let $container = $this.parents('[data-id="service-mapping-form-container"]');
				let $ddlIntegrationService = $container.find('[data-id="ddl-integration-service"]');
				let $ddlExternalService    = $container.find('[data-id="ddl-external-service"]');

				if (!$ddlIntegrationService.val() || !$ddlExternalService.val())
				{
					return;
				}

				$this.prop('disabled', true);

				let mappingId = $(this).data('addressMappingId');

				$.ajax({
						'url': `/api/v1/integrations/${self.integrationId}/matches/${mappingId}/service-map`,
						'contentType': 'application/json; charset=utf8',
						'type': 'PUT',
						'dataType': 'text',
						'data': JSON.stringify({
							'externalServiceId': $ddlExternalService.val(),
							'integrationServiceId': $ddlIntegrationService.val()
						})
					}).done(function () {
						self.fetchForm($panel);
					})
					.fail(function (x,y,z) {
						$this.prop('disabled', false);
					});

			});

		$container.find('[data-id="btn-remove-mapping"]')
			.on('click', function (e) {
				e.preventDefault();
				const $this = $(this);

				let addressMappingId = $this.data('addressMappingId');
				let serviceMappingId = $this.data('serviceMappingId');

				$.ajax({
					'url': `/api/v1/integrations/${self.integrationId}/matches/${addressMappingId}/service-map/${serviceMappingId}`,
					'contentType': 'application/json; charset=utf8',
					'type': 'DELETE',
				})
					.done(function () {
						self.fetchForm($panel);
					})
					.fail(function () {
						$this.prop('disabled', false);
					});
			});
	}



	static InitialiseDropDowns($container)
	{
		$container.find('select').select2({'width':'100%'});
	}
}
