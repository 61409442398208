const swal = require('sweetalert2');

export default class CalendarSettings {
	constructor (integrationId) {
		this.integrationId = integrationId;

		this.setupPanels();
	}

	setupPanels () {
		const self = this;

		$(document.body)
			.on('show.bs.collapse', '[data-id="collapsable-doctor-panel"]', function () {
				self.calendarForm($(this));
			});
	}

	calendarForm ($panel) {
		const self = this;

		let $serviceContainer = $panel.find('[data-id="calendar-settings-form-container"]');
		let addressMappingId = $panel.data('addressMappingId');

		if (0 === $serviceContainer.length) {
			return;
		}

		$.ajax({
			'url': `/integrations/${self.integrationId}/calendar-settings/${addressMappingId}/form`,
			'dataType': 'html',
			'method': 'GET'
		}).done(function (data) {

			$serviceContainer.html(data);

			self.setupButtons($panel, $serviceContainer);

		}).fail(function () {
			swal(
				'Oops...',
				'An error occured while fetching calendar settings',
				'error'
			)
		});

	}

	setupButtons ($panel, $container) {

		const self = this;

		$container
			.find('[data-id="btn-save-calendar-settings"]')
			.on('click', function (e) {
				e.preventDefault();

				const $this = $(this);

				let $addressMappingId = $panel.data('addressMappingId');
				let $last24h = $panel.find('[data-id="calendar-settings-last24h"]');
				let $weeksAhead = $panel.find('[data-id="calendar-settings-weeks-ahead"]');

				if (!$last24h.val() || !$weeksAhead.val()) {
					return;
				}

				$this.prop('disabled', true);
				let $isLast24h = String($last24h.val()) == 'true' ? 1 : 0;

				$.ajax({
					'url': `/api/v1/integrations/${self.integrationId}/matches/${$addressMappingId}/calendar-settings`,
					'contentType': 'application/json; charset=utf8',
					'type': 'PUT',
					'dataType': 'text',
					'data': JSON.stringify({
						'last24h': $isLast24h,
						'weeks_ahead': $weeksAhead.val()
					})
				})
					.done(function () {
						swal(
							'Success...',
							'Calendar settings updated.',
							'success'
						);
						$this.prop('disabled', false);
					})
					.fail(function () {
						swal(
							'Oops...',
							'An error occured while updating calendar settings',
							'error'
						);
						$this.prop('disabled', false);
					});
			});
	}

}