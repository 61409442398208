const swal = require('sweetalert2');

export default class MappingInterval {
	constructor (integrationId) {
		this.integrationId = integrationId;

		this.HandleUpdateInterval();
	}

	HandleUpdateInterval()
	{
		const self = this;
		$("[data-id=btn-save-mapping-visit-interval]").click(function () {
			let $mappingId = $(this).data('address-mapping-id');
			let $parent = $(this).parent();
			let $value = $parent.find('option:selected').val();
			let $sendValue = $value.length > 0 ? $value : null;

			$.ajax({
				contentType: 'application/json',
				type: 'POST',
				url: `/api/v1/integrations/${self.integrationId}/matches/${$mappingId}/visit-interval`,
				'dataType': 'text',
				'data': JSON.stringify({
					'visit_interval': $sendValue,
				})
			}).done(function () {
				swal(
					'Success...',
					'Mapping updated !',
					'success'
				);
			}).fail(function () {
				swal(
					'Error...',
					'Something went wrong.',
					'error'
				);
			})
		});
	}
}