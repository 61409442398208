export default class MappingList {
	constructor () {
		this.registerHandlers();
	}

	registerHandlers () {
		$('.mapping').on('click', this.handleMappingClick);
	}

	handleMappingClick (event) {
		let clicked = $(event.target);
		if (!clicked.is('a')) {
			let mapping = clicked.hasClass('mapping') ? clicked : clicked.closest('.mapping');

			window.location.href = mapping.find('.edit-link').attr('href');
		}
	}
}
