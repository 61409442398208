import '../../../../../node_modules/sweetalert2/dist/sweetalert2.all.min';
import '../../../../../node_modules/select2/dist/js/select2.full';
const swal = require('sweetalert2');

export default class IntegrationsList {
    constructor () {
		this.initOnClickTogglePanel();
        this.searchIntegration();
        this.changeStatus();
        this.unlock();
        this.hideIntegration();
        this.showIntegration();
        this.initFilters();
        this.filters();
    }

    _toggleHideClass(element) {
        element.toggleClass('hide');
    }

    hideIntegration()
	{
		let $hideButton = $("[data-action='hide-integration']");

		if(!$hideButton.length)
		{
			return;
		}

		$hideButton.click(function () {
			let $self = $(this);
			let $integrationId = $self.data('integration-id');

			$.ajax({
				type: 'POST',
				url: `/api/v1/integrations/${$integrationId}/hide`,
				dataType: 'json',
				contentType: 'application/json',
			}).done(function () {
				swal(
					'Success...',
					'Integration hidden !',
					'success'
				);
			}).fail(function () {
				swal(
					'Oops...',
					'Error occurred while hiding integration!',
					'warning'
				);
			});
		});
	}

	showIntegration()
	{
		let $hideButton = $("[data-action='show-integration']");

		if(!$hideButton.length)
		{
			return;
		}

		$hideButton.click(function () {
			let $self = $(this);
			let $integrationId = $self.data('integration-id');

			$.ajax({
				type: 'DELETE',
				url: `/api/v1/integrations/${$integrationId}/hide`,
				dataType: 'json',
				contentType: 'application/json',
			}).done(function () {
				swal(
					'Success...',
					'Integration unhidden !',
					'success'
				);
			}).fail(function () {
				swal(
					'Oops...',
					'Error occurred while trying un hide integration!',
					'warning'
				);
			});
		});
	}

	/**
	 * Show details
	 */
	initOnClickTogglePanel() {
		let self = this;
		$('body').on('click', '[data-id="show-more"]', function () {

			let parentDataId = $(this).data('parent');
			let $parent = $(this).parents(parentDataId);

			let targetDataId = $(this).data('target');
			let $target = $parent.find(targetDataId);

			self._toggleHideClass($target);
		});
	}

	/**
	 * Search Integration
	 */
	searchIntegration() {
		let $integrationInput = $('[data-id="search-integration"]');

		if (!$integrationInput.length) {
			return;
		}

		$integrationInput.on('keyup', function () {
			let $input = $('[data-id="search-integration"]');
			let filter = $input.val().toUpperCase();

			let $list = $('[data-id="integration-list"]');
			let $listElements = $list.find('[data-id="integration-element"]');

			$listElements.each(function () {
				let $integration = $(this);

				if ($integration.find('[data-id="integration-name"]').text().toUpperCase().indexOf(filter) > -1) {
					$integration.show();
				} else {
					$integration.hide();
				}
			});
		});
	}

	/**
	 * Change status
	 */
	changeStatus() {
		$('[data-id="status-change"]').on('change', function () {
			let $self = $(this);
			let integrationId = $self.data('integration-id');
			let status = $self.data('type');

			$self.parent()
				.removeClass('btn-default')
				.addClass($self.data('active-class'))
				.removeClass('active')
				.siblings()
				.each(
					function ()
					{
						$(this).addClass('btn-default')
							.removeClass('btn-success')
							.removeClass('btn-warning')
							.removeClass('btn-danger');
					});

			$.ajax({
				type: 'PATCH',
				url: `/api/v1/integrations/${integrationId}/status/${status}`,
				dataType: 'json',
				contentType: 'application/json',
			}).done(function () {

			}).fail(function () {

			});
		});
	}

	unlock(){
		$('.unlock-integration').click(function (){
			let $self = $(this);
			let integrationId = $self.data('integration-id');
			let $infoModal = $("#modal-info");

			$.ajax({
				type: 'PATCH',
				url: `/api/v1/integrations/${integrationId}/unlock`,
				dataType: 'json',
				contentType: 'application/json',
			}).done(function () {
				$infoModal.modal('show');
			}).fail(function () {
				alert('Internal error');
			});
		});
	}

	filters () {
		$("[data-action=apply-filters]").click(function () {
			let $allFilters = $('[data-type=filter]');
			let $activeFilters = [];

			$allFilters.each(function () {
				let filterData = {};

				if ($(this).is(':checked')) {
					filterData = {
						'name': $(this).data('filter-id')
					};
					$activeFilters.push(filterData);
					return;
				}

				if ($(this).is('select')) {
					let selected = $(this).find('option:selected');

					if(selected.length && selected.val() !== '') {
						filterData = {
							"name": $(this).data('filter-id'),
							"value": selected.val()
						};
						$activeFilters.push(filterData);
						return;
					}
				}
			});

			let $filterUrl = window.location.href.split('?')[0];

			if ($activeFilters.length > 0) {
				let $queryString = $.param({
					'filter': $activeFilters
				});

				window.location.href = $filterUrl + '?' + $queryString;
				return;
			}

			window.location.href = $filterUrl;
		});
	}

	initFilters()
	{
		$(".filters select.filter_software_provider").select2({
			placeholder: {
				text: "Software provider",
				id: ''
			},
			allowClear: true,
			closeOnSelect: false
		});

		$(".filters select.filter_integration_status").select2({
			placeholder: {
				text: "Status",
				id: ''
			},
			allowClear: true,
			closeOnSelect: false
		});
	}
}
