import api from './api';
const swal = require('sweetalert2');

export default class IntegrationNotifications {
	constructor (integrationId) {
		this.notifications = [];

		this.integrationId = integrationId;
		this.$container = $('[data-module="notifications"]');

		this.bind();
		this.list();
	}

	bind () {
		const self = this;

		this.$container.on('submit', 'form', function (e) {
			e.preventDefault();

			const $this = $(this);
			const notification = {
				email: $this.find('[name="notification[email]"]').val()
			};

			api.addIntegrationNotification(self.integrationId, notification)
				.catch(() => {
					swal(
						'Oops...',
						'Could not add notification!',
						'error'
					);
				})
				.then(() => {
					return self.list();
				});
		});

		this.$container.on('click', '[data-id="btn-remove-notification"]', function () {
			let $this = $(this),
				$parent = $this.parents('[data-id="notification-item"]')
				.first();

			api.removeIntegrationNotification(self.integrationId, $this.data('notificationId'))
				.then(() => {
					self.list();
					$parent.detach();
				})
				.catch(() => {
					swal(
						'Oops...',
						'Could not remove notification!',
						'error'
					);
				});


		});
	}

	list () {
		api.getIntegrationNotifications(this.integrationId)
			.then((notifications) => { this.notifications = notifications._items; })
			.catch((error) => {
				swal(
					'Oops...',
					'Could not get notification list!',
					'error'
				);
			})
			.then(() => {
				this.render();
			});
	}

	render () {
		const notificationsHtml = [];

		this.notifications.forEach(function (notification) {

			notificationsHtml.push(IntegrationNotifications.getNotificationRow(notification));

		});

		notificationsHtml.push(IntegrationNotifications.getForm());

		this.$container.html(notificationsHtml.join(''));
	}

	static getForm () {
		return `<div class="row offset-top-1" data-id="notification-item">
					<form data-id="notification-form">
						<div class="col-md-2 col-xs-12 integration-edit__form-element text-right">
							<label>E-Mail:</label>
						</div>
						<div class="col-md-9 col-xs-12">
							<input autocomplete="off" 
									type="email" 
									class="form-control" 
									name="notification[email]" 
									placeholder="example@docplanner.io" required>
						</div>
						<div class="col-md-1 col-xs-12 offset-xs-top-1 text-right">
								<button class="btn btn-primary"
										type="submit"
										data-id="btn-save-notification">
									<i class="fa fa-floppy-disk" aria-hidden="true"></i>
									save
								</button>
						</div>
					</form>
				</div>`;
	}

	static getNotificationRow (notification) {
		return `<div class="row offset-top-1" data-id="notification-item">
						<div class="col-md-2 col-xs-12 integration-edit__form-element text-right">
							<label>E-Mail:</label>
						</div>
						<div class="col-md-9 col-xs-12">
							${notification.email}
						</div>
						<div class="col-md-1 col-xs-12 offset-xs-top-1 text-right">			
							<button class="btn btn-danger"
									type="button"
									data-notification-id="${notification.id}"
									data-id="btn-remove-notification">
								<i class="fa fa-trash-o offset-right-0" style="margin-right: 0" aria-hidden="true"></i>
							</button>
						</div>
				</div>`;
	}
}
