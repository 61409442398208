import moment from '../../../../../node_modules/moment/moment.js';
import '../../../../../node_modules/select2/dist/js/select2.min';
import forOwn from 'lodash/forOwn';
import CalendarNotifications from './calendarNotifications';

export default class IntegrationPage {
	constructor (integrationId) {
		const self = this;
		this.integrationId = integrationId;
		this.showAntiFloodHistoryModal();
		this.showContactDataModal();
		this.sendDoctorRefreshRequest();
		this.sendImportInsurancesRequest();
		this.searchDoctor();
		this.filters();
		this.initFacilitySelect();
		this.registerDoctorPanelBindings();
		this.sortOnlineAddresses();
		this.sendDeleteIntegrationRequest();
		this.sendRestoreIntegrationRequest();

		$('body').on('click', '[data-id="push-slots"]', function ()
		{
			let $this = $(this);

			const addressId = $this.data('addressId'),
				  doctorId = $this.data('doctorId');

			$this.prop('disabled', true);

			self.pushSlots(addressId, doctorId)
				.done(() => window.alert('Doctor calendar synchronized'))
				.fail(() => window.alert('Error when synchronizing calendars'))
				.always(function () {
					$this.prop('disabled', false);
				});
		});
		this.elementRotate();

		$('.js-sync-matched-addresses').on('click', function()
		{
			this.disabled = true;

			self.pushSlotsForEachMatchedAddress()
				.fail((xhr, textStatus, error) => alert(error))
				.always(() => this.disabled = false);
		});
	}

	static _formatSlotsData(slotsData)
	{
		let html = [];
		let slots  = [];
		let first = true;

		if(slotsData.length === 0)
		{
			return `<div class="text-center"><span class="text-muted">No slots found :(</span></div>`;
		}

		forOwn(slotsData, (value, key) => { slots.push({ date: key, times: value }); });

		for(let day of slots)
		{
			html.push(
				`
				<div class="row offset-0" id="accordion-${day.date}">
					<h4 class="vert-offset-bottom-1 text-center" id="heading-${day.date}">
						<a class="btn btn-default btn-lg btn-block" role="button" data-toggle="collapse" data-parent="#accordion-${day.date}" href="#collapse-${day.date}" aria-expanded="${first ? 'true' : 'false'}" aria-controls="collapse-${day.date}">
							${day.date} (${day.times.length} slots)
						</a>
					</h4>
					<div id="collapse-${day.date}" class="collapse ${first ? 'in' : ''}" role="tabpanel" aria-labelledby="heading-${day.date}">
						<div class="well">
							<ul class="row list-unstyled vert-offset-bottom-0">${IntegrationPage._getSlotsForDay(day.times)}</ul>
						</div>
					</div>
				</div>`
			);

			first = false;
		}

		return html.join('');
	}

	static _getSlotsForDay(slots)
	{
		let slotsHtml = [];

		for (let i = 0; i < slots.length; i++)
		{
			let slot = slots[i];
			let startTime = moment(slot['start']).format('HH:mm:ss');
			let endTime = moment(slot['end']).format('HH:mm:ss');
			let timezone = moment(slot['start']).format('Z');
			let service = slot['service'] || '';

			if (service !== '')
			{
				service = `<span>Service ID: ${service}</span>`;
			}

			slotsHtml.push(
				`<li class="col-md-6 text-muted" style="margin: 2px 0; border-left:2px solid #12adf5;">
					<div class="clearfix">
						<span class="pull-left ">${startTime} - ${endTime}</span>
						<span class="pull-right">${timezone}</span>
						<span class="pull-right offset-right-1">${slots[i]['duration']} minutes</span>
					</div>
					<div class="clearfix">
						${service}
					</div>
				</li>`
			);
		};

		return slotsHtml.join('');
	}

	/**
	 * Free slots data display
	 */
	_sendSlotsModalAjax ($btn) {
		let self = this;
		let $body = $('body');

		const addressId = $btn.data('addressId'),
			doctorId = $btn.data('doctorId'),
			doctorName = $btn.data('doctorName'),
			addressName = $btn.data('addressName');

		return $.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			type: 'GET',
			url: `/api/v1/integrations/${this.integrationId}/external/doctors/${doctorId}/addresses/${addressId}/slots`,
			dataType: 'json',
			statusCode: {
				200: function (data) {

					let slotsData = data.slots;
					let allDataText = IntegrationPage._formatSlotsData(slotsData);
					let mappableServices = data.services.join(', ') || 'No service was sent with schedules';

					// remove modal if already exists
					let $slotsModal = $('.free-slots-modal');

					if ($slotsModal.length) {
						$slotsModal.remove();
					}

					// create new modal
					$body.append(`
						<div class="free-slots-modal" data-id="${addressId}">
							<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="free hours">
								<div class="modal-dialog" style="width:700px" role="document">
									<div class="modal-content">
										<div class="modal-header"><a class="close" data-dismiss="modal">×</a>
											<h4 class="offset-bottom-0">
												${doctorName}</br><span class="small vert-offset-top-1">${addressName}</span>
											</h4>
										</div>
										<div class="modal-body">
											<h4>Available integration services: </h4>
											<div class="well"><div class="text-info">${mappableServices}</div></div>
											${allDataText}
										</div>
									</div>
								</div>
							</div>
						</div>
					`);

					let $appendedModal = $body.find('.free-slots-modal .modal');
					$appendedModal.modal('show');
				},
				404: function () {
				}
			}
		});

	}

	_sendAntiFloodHistoryModalAjax (btn) {
		let self = this;
		let $body = $('body');

		return $.ajax({
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			type: 'GET',
			url: `/api/v1/integrations/${this.integrationId}/anti-flood-history`,
			dataType: 'json',
			statusCode: {
				200: function (data) {

					// remove modal if already exists
					let $antiFloodHistoryModal = $('.anti-flood-history-modal');

					if ($antiFloodHistoryModal.length) {
						$antiFloodHistoryModal.remove();
					}

					let historyContent = [];
					for (let id in data) {
						historyContent.push(`<li>${data[id]}</li>`);
					}

					$body.append(`
						<div class="anti-flood-history-modal" data-id="${self.integrationId}">
							<div class="modal fade" tabindex="-1" role="dialog">
								<div class="modal-dialog" style="width:700px" role="document">
									<div class="modal-content">
										<div class="modal-header"><a class="close" data-dismiss="modal">×</a>
											<h3 class="offset-bottom-0">
												Anti-flood history
											</h3>
										</div>
										<div class="modal-body">
											<h4>Registered command identifiers:</h4>
											<ul>
												${historyContent.join('')}
											</ul>
										</div>
										<div class="modal-footer">
											<div class="btn btn-danger" data-id="clear-anti-flood-history" title="Clear anti-flood history">
												<i class="fa fa-trash-o" aria-hidden="true" title=""></i>
												<span>&nbsp;Clear history</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					`);

					let $appendedModal = $body.find('.anti-flood-history-modal .modal');
					$appendedModal.modal('show');
					self.clearAntiFloodHistory();
				},
				404: function () {
				}
			}
		});

	}

	showAntiFloodHistoryModal () {
		let self = this;

		$('body').on('click', '[data-id="show-anti-flood-history"]', function () {
			let $this = $(this);

			$this.prop('disabled', true);

			self._sendAntiFloodHistoryModalAjax($this)
				.always(function () {
					$this.prop('disabled', false);
				});
		});
	}

	/**
	 * Free slots data modal constructor
	 */
	showContactDataModal () {
		let self = this;

		$('body').on('click', '[data-id="show-free-slots"]', function () {
			let $this = $(this);

			$this.prop('disabled', true);

			self._sendSlotsModalAjax($this)
				.always(function () {
					$this.prop('disabled', false);
				});
		});
	};

	pushSlots(addressId, doctorId)
	{
		return $.ajax({
			url: `/api/v1/integrations/${this.integrationId}/external/doctors/${doctorId}/addresses/${addressId}/slots`,
			method: 'PUT'
		});
	}

	pushSlotsForEachMatchedAddress()
	{
		return $.ajax({
			url: `/api/v1/integrations/${this.integrationId}/matches/sync`,
			method: 'PUT'
		});
	}

	/**
	 * Doctors refresh request
	 */
	sendDoctorRefreshRequest () {
		let $infoModal = $("#modal-info");
		const self = this;

		$('[ data-id="import-external-doctors"]').on('click', function () {
			$.ajax({
				type: 'PUT',
				url: `/api/v1/integrations/${self.integrationId}/external/doctors`
			}).done(function () {
				$infoModal.modal('show');
			}).fail(function () {
				alert('Error');
			});
		});

		$('[ data-id="import-integration-doctors"]').on('click', function () {
			$.ajax({
				type: 'PUT',
				url: `/api/v1/integrations/${self.integrationId}/doctors`
			}).done(function () {
				$infoModal.modal('show');
			}).fail(function (xhr) {
				if (xhr.status === 409) {
					alert('Error! Check if tuotempo area id is configured.')
				} else {
					alert('Error');
				}
			});
		});
	};

	sendImportInsurancesRequest () {
		let $infoModal = $("#modal-insurances-import");
		const self = this;

		$('[ data-id="import-tuotempo-insurances"]').on('click', function () {
			$.ajax({
				type: 'GET',
				url: `/api/v1/integrations/${self.integrationId}/insurances/import`
			}).done(function () {
				$infoModal.modal('show');
			}).fail(function (xhr) {
				if (xhr.status === 409) {
					alert('Error! Check if default tuotempo insurance id is configured.')
				} else {
					alert('Error');
				}
			});
		});
	}

	sendDeleteIntegrationRequest () {
		const self = this;

		$('[ data-id="delete-integration"]').on('click', function () {
			if (confirm('Do you really want to delete this integration? All linked facilities and address mappings will be removed.')) {
				$.ajax({
					type: 'PATCH',
					url: `/api/v1/integrations/${self.integrationId}/delete`
				}).done(function () {
					window.location.reload();
				}).fail(function (xhr) {
					alert(xhr.responseJSON);
				});
			}
		});
	}

	sendRestoreIntegrationRequest() {
		const self = this;

		$('[ data-id="restore-integration"]').on('click', function () {
			if (confirm('Do you really want to restore this integration?')) {
				$.ajax({
					type: 'PATCH',
					url: `/api/v1/integrations/${self.integrationId}/status/disabled`
				}).done(function () {
					window.location.reload();
				}).fail(function () {
					alert('Error');
				});
			}
		});
	}

	clearAntiFloodHistory() {
		const self = this;

		$('[ data-id="clear-anti-flood-history"]').on('click', function () {
			if (confirm('Do you really want to clear anti-flood history for this integration?')) {
				$.ajax({
					type: 'DELETE',
					url: `/api/v1/integrations/${self.integrationId}/anti-flood-history`
				}).done(function () {
					window.location.reload();
				}).fail(function () {
					alert('Error');
				});
			}
		});
	}

	/**
	 * Icon Rotate
	 */
	elementRotate () {
		$('[data-id="show-more"] i').click(function(){
			$(this).toggleClass('rotate');
		});
	}

	searchDoctor() {
		let $integrationInput = $('[data-id="search-doctor"]');

		if (!$integrationInput.length) {
			return;
		}

		$integrationInput.on('keyup', function () {
			let $input = $('[data-id="search-doctor"]');
			let filter = $input.val().toUpperCase();

			let $list = $('[data-id="doctors-list"]');
			let $listElements = $list.find('[data-id="parent"]');

			$listElements.each(function () {
				let $integration = $(this);
				let $integrationAddressId = $integration.data('integration-address-id');
				let $hr = $('[data-hr-integration-address-id="' + $integrationAddressId + '"]');

				if ($integration.find('[data-id="doctor-name"]').text().toUpperCase().indexOf(filter) > -1) {
					$integration.show();
					$hr.show();
				} else {
					$integration.hide();
					$hr.hide()
				}
			});
		});
	}

	filters () {
		$("[data-action=apply-filters]").click(function () {
			let $allFilters = $('[data-type=filter]');
			let $activeFilters = [];

			$allFilters.each(function () {
				let filterData = {};

				if ($(this).is(':checked')) {
					filterData = {
						'name': $(this).data('filter-id')
					};
					$activeFilters.push(filterData);
					return;
				}

				if ($(this).is('select')) {
					let selected = $(this).find('option:selected');

					if(selected.length && selected.val() !== '') {
						filterData = {
							"name": $(this).data('filter-id'),
							"value": selected.val()
						};
						$activeFilters.push(filterData);
						return;
					}
				}
			});

			let $filterUrl = window.location.href.split('?')[0];

			if ($activeFilters.length > 0) {
				let $queryString = $.param({
					'filter': $activeFilters
				});

				window.location.href = $filterUrl + '?' + $queryString;
				return;
			}

			window.location.href = $filterUrl;
		});
	}

	initFacilitySelect () {
		let $facilitySelect = $('[data-id="facility-select"]');
		let $rows = $('[data-integration-address-id]');
		let $options = [...new Set($.map($('[data-integration-address-id] > div:nth-child(3) > h5'), item => item.innerText))];

		$facilitySelect.select2({
			data: $options,
			multiple: true,
			theme: 'bootstrap',
			placeholder: 'Choose facilities'
		});

		$facilitySelect.on('change', () => {
			const $selected = $facilitySelect.select2('data');

			if (0 === $selected.length) {
				$rows.removeClass('filtered-out');
				return;
			}

			$rows.addClass('filtered-out');
			$selected.forEach(item => $rows.find(`h5:contains("${item.text}")`).closest('[data-id=parent]').removeClass('filtered-out'));
		})
	}

	registerDoctorPanelBindings () {
		$(document.body)
			.on('show.bs.collapse', '[data-id="collapsable-doctor-panel"]', function () {
				if(!$(this).find('[data-id=calendar-notifications-emails]').data('initialized'))
				{
					new CalendarNotifications($(this).data('doctor-id'), $(this).data('address-id'), $(`[data-address-id=${$(this).data('address-id')}]`));
				}
			})
	}

	sortOnlineAddresses () {
		$('[data-online=true]').each(function () {
			const compositeId = $(this).data('composite-id');
			const parentCompositeId = compositeId.substr(0, compositeId.length - 7);
			let parent = $(`[data-composite-id="${parentCompositeId}"]`);
			let hr = $(this).next('hr');

			$(this).detach();
			hr.detach();
			parent.after($(this));
			parent.after(hr);
		});
	}
}
