export default class CommuniqueCreate
{
	constructor () {
		this.$form = $('[data-id="create-communique-form"]').find('form');

		if (this.$form) {
			this.HandleFieldChanges();
			this.HandleSubmit();
			this._ShowHideTargetFields(this.$form.find('[name="target"]').val());
		}
	}

	HandleFieldChanges() {
		let self = this;

		$('[data-js=show-target-fields]').on('change', function () {
			let $this = $(this);

			self._ShowHideTargetFields($this.val());
		});
	}

	_ShowHideTargetFields(targetFieldValue) {
		let $integrationRow = $('.js-integration-field'),
			$vendorRow = $('.js-vendor-field');

		switch (targetFieldValue) {
			case 'all':
				$integrationRow.hide().find('select').val('').removeAttr('required');
				$vendorRow.hide().find('select').val('').removeAttr('required');
				break;
			case 'single':
				$integrationRow.show().find('select').attr('required', true);
				$vendorRow.hide().find('select').val('').removeAttr('required');
				break;
			case 'vendor':
				$integrationRow.hide().find('select').val('').removeAttr('required');
				$vendorRow.show().find('select').attr('required', true);
				break;
		}
	}

	HandleSubmit () {
		this.$form.on('submit', (e) => {
			e.preventDefault();

			this.SubmitForm();
		});
	}

	SubmitForm () {
		return $.ajax({
			type: 'POST',
			dataType: 'json',
			url: '/api/v1/communiques',
			contentType: 'application/json',
			data: JSON.stringify(this.GetFormData()),
			statusCode: {
				201: function (data) {
					window.location = data.getResponseHeader('Location');
				},
				400: function (data) {
					let $warningAlert = $('[data-id="warning-alert"]');

					$warningAlert.removeClass('hide')
						.text(`Please check the form`);

					$('html, body').animate({
						scrollTop: $('main').position().top
					}, 800);
				},
				500: function (data) {
					let $warningAlert = $('[data-id="warning-alert"]');

					$warningAlert.removeClass('hide')
						.text(`An error occured please try again later`);

					$('html, body').animate({
						scrollTop: $('main').position().top
					}, 800);
				}
			}
		});
	}

	GetFormData () {
		let data = {
			content: this.$form.find('[name="content"]').val(),
			duration: this.$form.find('[name="duration"]').val(),
			target: this.$form.find('[name="target"]').val()
		};

		switch (data.target) {
			case 'vendor':
				data.vendor = this.$form.find('[name="vendor"]').val();
				break;
			case 'single':
				data.vendor = this.$form.find('[name="single"]').val();
				break;
		}

		return data;
	}
}
