import '../../../../../node_modules/select2/dist/js/select2.full';

export default class IntegrationCreate {
	constructor () {

		this.$facilitySelect = $('[data-id="ddl-facility"]');
		this.$form = $('[data-id="create-integration-form"]').find('form');

		if (this.$form) {
			this.SetupFacilitySelect();
			this.HandleSubmit();
		}
	}

	SetupFacilitySelect () {
		this.$facilitySelect.select2({
			cache: true,
			width: '100%',
			minimumInputLength: 3,
			ajax: {
				delay: 500,
				data: null,
				url: function (params) {
					return `/api/v1/external/facilities/${encodeURIComponent(params.term)}`
				},
				processResults: function (results) {
					return { results };
				}
			},

			templateResult: function (data) {
				if(!data.id)
				{
					return 'loading...';
				}

				return `${data.id} ${data.name || ''} ${data.street || ''} ${data.city || ''}`;
			},

			templateSelection: function (data) {
				return `${data.id} ${data.name}`;
			}
		});
	}

	GetWorkers () {
		return [
			{
				type: "fetch-calendar",
				interval: this.$form.find('[name="fetch-calendar-interval"]').val(),
				timeout: this.$form.find('[name="fetch-calendar-timeout"]').val(),
				grace: this.$form.find('[name="fetch-calendar-grace"]').val(),

			},
			{
				type: "import-doctors",
				interval: this.$form.find('[name="import-doctors-interval"]').val(),
				timeout: this.$form.find('[name="import-doctors-timeout"]').val(),
				grace: this.$form.find('[name="import-doctors-grace"]').val(),
			},
			{
				type: "check-webhook",
				interval: this.$form.find('[name="check-webhook-interval"]').val(),
				timeout: this.$form.find('[name="check-webhook-timeout"]').val(),
				grace: this.$form.find('[name="check-webhook-grace"]').val(),
			}
		];
	}

	GetFacilitySelections () {
		let selections = this.$facilitySelect.select2('data');

		return selections.map(function (item) {
			return {
				id: item.id,
				name: item.name
			}
		});
	}

	GetFormData () {
		return {
			name: this.$form.find('[name="name"]').val(),
			type: this.$form.find('[name="webhook-type"]').val(),
			url: this.$form.find('[name="url"]').val(),
			user: this.$form.find('[name="user"]').val(),
			secret: this.$form.find('[name="secret"]').val(),
			token: this.$form.find('[name="token"]').val(),
			workers: this.GetWorkers(),
			facilities: this.GetFacilitySelections(),
			note: this.$form.find('[name="note"]').val(),
			supportEmail: this.$form.find('[name="support-email"]').val(),
			pingUrl: this.$form.find('[name="ping-url"]').val()
		};
	}

	SendRequest () {
		return $.ajax({
			type: 'POST',
			dataType: 'json',
			url: '/api/v1/integrations',
			contentType: 'application/json',
			data: JSON.stringify(this.GetFormData()),
			statusCode: {
				201: function (data) {
					window.location = data.getResponseHeader('Location');
				},
				400: function (data) {
					let $warningAlert = $('[data-id="warning-alert"]');

					$warningAlert.removeClass('hide')
						.text(`Please check the form`);

					$('html, body').animate({
						scrollTop: $('main').position().top
					}, 800);
				},
				409: function (data) {
					let $warningAlert = $('[data-id="warning-alert"]');

					$warningAlert.removeClass('hide')
						.text(`The facilities you've selected are already attached to another integration`);

					$('html, body').animate({
						scrollTop: $('main').position().top
					}, 800);
				},
				500: function (data) {
					let $warningAlert = $('[data-id="warning-alert"]');

					$warningAlert.removeClass('hide')
						.text(`An error occured please try again later`);

					$('html, body').animate({
						scrollTop: $('main').position().top
					}, 800);
				}
			}
		});
	}

	HandleSubmit () {
		this.$form.on('submit', (e) => {
			e.preventDefault();

			this.SendRequest();
		});
	}
}
