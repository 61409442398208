export default class Api {
	static createRequest (method, uri, body) {
		let json = !!body ? JSON.stringify(body) : undefined;
		let length = !!json ? json.length : 0;
		let headers = new Headers({'Content-Type': 'application/json', 'Content-Length': length});

		return new Request(uri, {method, headers, body: json, credentials: 'include'});
	};

	static getIntegrationNotifications (integrationId) {
		const request = Api.createRequest('GET', `/api/v1/integrations/${integrationId}/notifications`);

		return fetch(request)
			.then((response) => response.json());
	}

	static removeIntegrationNotification (integrationId, notificationId) {
		const request = Api.createRequest('DELETE', `/api/v1/integrations/${integrationId}/notifications/${notificationId}`);

		return fetch(request);
	}

	static addIntegrationNotification(integrationId, notification)
	{
		const request = Api.createRequest(
			'POST',
			`/api/v1/integrations/${integrationId}/notifications`,
			notification
		);

		return fetch(request);
	}
}
