export default class CommuniqueList
{
	constructor () {
		this.InvalidateCommunique();
	}

	InvalidateCommunique () {
		let self = this;

		$('body').on('click', '.js-invalidate-communique', function() {
			let $this = $(this),
				id = $this.data('communique-id');

			$this.attr('disabled', true);
			self._InvalidateCommuniqueRequest(id, $this);
		})
	}

	_InvalidateCommuniqueRequest (communiqueId, $element) {
		return $.ajax({
			type: 'DELETE',
			dataType: 'json',
			url: `/api/v1/communiques/${communiqueId}`,
			contentType: 'application/json',
			statusCode: {
				204: function () {
					const row = $(`tr[data-communique-id="${communiqueId}"]`);
					row.remove();
				},
				500: function () {
					let $warningAlert = $('[data-id="warning-alert"]');

					$warningAlert.removeClass('hide')
						.text(`An error occured please try again later`);

					$('html, body').animate({
						scrollTop: $('main').position().top
					}, 800);
				}
			},
			complete: function() {
				$element.removeAttr('disabled');
			}
		});
	}
}
